<template>
    <section class="lock" @click.stop="pickerclick">
        <el-button type="primary" size="small" @click="openDialog" v-if="setPassWordShow && iotTag === '0'">设置密码
        </el-button>
        <el-button type="primary" size="small" @click="openDialog1" v-show="iotTag === '1'||iotTag === '2'">设置密码
        </el-button>
        <el-button type="primary" size="small" @click="enableCard" v-if="iotTag === '2'">启用卡片</el-button>
        <el-button type="primary" size="small" @click="disableCard" v-if="iotTag === '2'">禁用卡片</el-button>
        <el-button type="primary" size="small" @click="dcLock">生成租户密码</el-button>
        <el-button type="danger" size="small" @click="lockClear">清空</el-button>
        <el-button type="primary" size="small" @click="look">查看开锁记录</el-button>
        <!-- <el-button type="primary" size="small" @click="Unlock">查看开锁记录1</el-button> -->
        <el-button type="primary" size="small" @click="replace">更换门锁</el-button>
      <el-button type="primary" size="small" @click="operatingrecord">操作记录</el-button>
       <!-- 2024/06/11 张晓瑜新增绑定电表 -->
       <el-button type="primary" size="small" :underline="false" @click="BindingElectricity">绑定电表</el-button>
<!--      修改规则日志-->
      <el-button type="primary" size="small" v-if="contractIsValid()"  @click="operateRulesRecording">修改规则日志</el-button>
       <!-- 2024/10/17 张晓瑜新增门锁处理 -->
       <!-- <el-button type="primary" size="small" :underline="false" @click="DoorLockProcessing">门锁处理</el-button> -->
        <r-e-table ref="lockIdTableRef" :columns="tableColumnLockId" :dataRequest="getLocks" :query="formSearch"
                   :height="750">
            <el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span v-if="(row.state === 66 && row.type === 1)||(row.state === 66 && row.type === 2)"
                              class="table-btn" @click="passDel(row)">删除</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>
        <dialog-set-lock-temporary ref="dialogSetLockTemporary" :equipmentData="equipmentData"/>
        <dialog-set-lock-temporarys ref="dialogSetLockTemporarys" :equipmentData="equipmentData"/>
        <dialog-show-unlock-record ref="dialogShowUnlockRecord" :equipmentData="equipmentData"/>
        <!-- 新增查看开锁记录 -->
        <dialog-unlock-two-record ref="dialogUnlockTwoRecord" :equipmentData="equipmentData"/>
        <dialogr-replace-lock ref="dialogrReplaceLock" :equipmentData="equipmentData"/>
      <!--       操作记录-->
      <dialogoperatingrecord ref="operatingrecordref" :equipmentData="equipmentData"></dialogoperatingrecord>
<!--      操作规则记录-->
      <operateRulesRecordingDialog ref="operateRulesRecordingDialogREF" :equipmentData="equipmentData"></operateRulesRecordingDialog>
        <!-- 绑定电表 -->
      <dialog-binding-electricity ref="dialogBindingElectricity" :equipmentData="equipmentData" @confirm="handleConfirm"/>
      <!-- 门锁处理 -->
      <dialog-door-lock-processing ref="dialogDoorLockProcessing" :equipmentData="equipmentData" @confirm="handleConfirm"/>
    </section>
</template>

<script>
import {
    getLocks,
    lockClear,
    dcLock,
    passDel,
    getMenu,
    enableCardAPI,
    synchronizationKeyInfoAPI
} from "@/api/door-management";
import {MessageSuccess, MessageError, MessageInfo} from "@custom/message";
import {tableColumnLockId} from "@/views/property-management/door-management/data";

export default {
    name: "lock",
    props: {
        equipmentData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            tableColumnLockId: tableColumnLockId(this),
            formSearch: {},
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            setPassWordShow: false,
            iotTag: 0
        };
    },
    components: {
        dialogSetLockTemporary: () => import("./components/dialog-set-lock-temporary"),
        dialogSetLockTemporarys: () => import("./components/dialog-sets-lock-temporarys"),
        dialogShowUnlockRecord: () => import("./components/dialog-show-unlock-record"),
        dialogUnlockTwoRecord: () => import("./components/dialog-unlock-two-record .vue"),
        dialogrReplaceLock: () => import("./components/dialog-replace-lock"),
        dialogoperatingrecord: () => import("./components/dialog-operatingrecord"),
        operateRulesRecordingDialog: () => import("./components/operate-rules-Recording-dialog"),
        dialogBindingElectricity:()=> import('./components/dialog-binding-electricity'),
        dialogDoorLockProcessing:()=> import('./components/dialog-door-lock-processing')
    },
    mounted() {

        let {apartmentUuid: uuid, iotTag} = this.equipmentData;
        this.iotTag = iotTag
        getMenu(uuid).then(({info}) => {
            this.setPassWordShow = info;
        });
        const loadingOptions = this.loadingOptions;
        const loading = this.$loading({...loadingOptions});
        synchronizationKeyInfoAPI({apartmentUuid: uuid}).finally(() => loading.close());
    },
    methods: {
      contractIsValid(){
          let itr = this.equipmentData.apartmentName.substring(0, 2);
          if(itr === '五象')return true
          return false
      },
      pickerclick(){
        console.log('044444444444444444444433300')
      },
        openDialog() {
            const {lockId} = this.equipmentData;
            if (lockId === null) {
                MessageError('当前房源没有智能门锁无法发送');
                return;
            }
            this.$refs["dialogSetLockTemporary"].openDialog(lockId);
        },
        // 张晓瑜新增iotTag = 1的下发离线密码 弹窗
        openDialog1() {
            const {lockId} = this.equipmentData;
            if (lockId === null) {
                MessageError('当前房源没有智能门锁无法发送');
                return;
            }
            this.$refs["dialogSetLockTemporarys"].openDialog(lockId);
        },
        dcLock() {
            const that = this;
            const {uuid, leasorPhone, lockId} = this.equipmentData;
            if (lockId === null) {
                MessageError('当前房源没有智能门锁无法发送');
                return;
            }
            if (leasorPhone === null) {
                MessageError('当前房源没有租户无法发送');
                return;
            }

            that.$confirm('是否删除历史密码?', '提示', {
                confirmButtonText: '是', cancelButtonText: '否', type: 'warning'
            }).then(() => {
                const loadingOptions = this.loadingOptions;
                const loading = this.$loading({...loadingOptions});
                dcLock({uuid, phone: leasorPhone, isDelete: true}).then(res => {
                    that.$alert('生成租户密码成功', '密码提醒', {type: 'success', confirmButtonText: '确定'});
                    MessageSuccess('生成租户密码成功');
                    this.handleSearchLocks();
                    loading.close();
                }).catch(err => loading.close());
            }).catch(() => {
                const loadingOptions = this.loadingOptions;
                const loading = this.$loading({...loadingOptions});
                dcLock({uuid, phone: leasorPhone, isDelete: false}).then(res => {
                    that.$alert('生成租户密码成功', '密码提醒', {type: 'success', confirmButtonText: '确定'});
                    MessageSuccess('生成租户密码成功');
                    this.handleSearchLocks();
                    loading.close();
                }).catch(err => loading.close());
            });
        },
        lockClear() {
            const {uuid, leasorPhone, lockId} = this.equipmentData;
            if (lockId === null) {
                MessageInfo('当前房源没有智能门锁无需清空');
                return;
            }
            const loadingOptions = this.loadingOptions;
            const loading = this.$loading({...loadingOptions});
            let paramData = {uuid, phone: leasorPhone};
            lockClear(paramData).then(res => {
                MessageSuccess('清空门锁密码成功');
                loading.close();
            }).catch(err => loading.close());
        },
        getLocks(params) {
            params.uuid = this.equipmentData.uuid;
            return getLocks(params);
        },
        handleSearchLocks() {
            this.$refs["lockIdTableRef"].getTableData();
        },
        passDel(data) {
            let {uuid} = data;
            this.$confirm('此操作改密码将永久不可用, 是否继续?', '提示', {
                type: 'warning'
            }).then(() => {
                const loadingOptions = this.loadingOptions;
                const loading = this.$loading({...loadingOptions});
                passDel(uuid).then(res => {
                    MessageSuccess('不可用租户密码成功');
                    setTimeout(() => {
                        loading.close();
                        this.handleSearchLocks();
                    }, 2000);
                }).catch(err => loading.close());
            }).catch(() => {
            });
        },
        // 开锁记录
        look() {
            const {uuid,iotTag} = this.equipmentData;
            this.$refs["dialogShowUnlockRecord"].openDialog(uuid,iotTag);
        },
        // 2023/08/31 张晓瑜新增
        // 开锁记录
        Unlock() {
            const {uuid} = this.equipmentData;
            this.$refs["dialogShowUnlockRecord"].openDialog(uuid);
        },

        replace() {
            this.$refs["dialogrReplaceLock"].openDialog();
        },

        //禁用管理卡
        disableCard() {
            const that = this;
            const {uuid} = this.equipmentData;
            that.$confirm('是否禁用卡片?', '提示', {
                confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
            }).then(() => {
                const loadingOptions = this.loadingOptions;
                const loading = this.$loading({...loadingOptions});
                enableCardAPI({apartmentUuid: uuid, stateFlag: false}).then(res => {
                    that.$alert('禁用卡片成功', '提醒', {type: 'success', confirmButtonText: '确定'});
                    that.handleSearchLocks();
                    loading.close();
                }).catch(err => loading.close());
            }).catch(() => {
            });
        },

        enableCard() {
            const that = this;
            const {uuid} = this.equipmentData;
            const loadingOptions = this.loadingOptions;
            const loading = this.$loading({...loadingOptions});
            that.$confirm('是否启用卡片?', '提示', {
                confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
            }).then(() => {
                enableCardAPI({apartmentUuid: uuid, stateFlag: true}).then(res => {
                    that.$alert('启用卡片成功', '提醒', {type: 'success', confirmButtonText: '确定'});
                    that.handleSearchLocks();
                    loading.close();
                }).catch(err => loading.close());
            }).catch(() => {
            });
        },
      //   2024-3-13-陈守亮-操作记录
      operatingrecord(){
        const {uuid} = this.equipmentData;
        this.$refs["operatingrecordref"].openDialog(uuid);
      },
      operateRulesRecording(){
        const {uuid} = this.equipmentData;
        this.$refs["operateRulesRecordingDialogREF"].openDialog(uuid);
      },
    //  2024/06/11 张晓瑜新增绑定电表
    BindingElectricity(){
        this.$refs["dialogBindingElectricity"].openDialog();
    },
    //  2024/06/11 张晓瑜新增绑定电表
    DoorLockProcessing(){
        const {uuid} = this.equipmentData;
        this.$refs["dialogDoorLockProcessing"].openDialog(uuid);
    },
    handleConfirm(){
        this.$emit('handleSearch');
    }
    }
}
</script>

<style scoped>

</style>
